module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nowy Świat Fizjoterapii Anna Roczniak-Zubrycka - Fizjoterapia, Supraśl","short_name":"Nowy Świat Fizjoterapii","description":"Gabinet \"Nowy Świat Fizjoterapii\" znajduje się w Supraślu, oddalony 10 min jazdy samochodem od Białegostoku.","start_url":"/","background_color":"#F4F4F4","theme_color":"#F4F4F4","display":"standalone","icon":"src/assets/images/globe.svg","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"a0fddec33866450def8bfd2dd623212c"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com"],"web":[{"name":"Cinzel","file":"https://fonts.googleapis.com/css2?family=Cinzel:wght@600&display=swap"},{"name":"Fauna One","file":"https://fonts.googleapis.com/css2?family=Fauna+One&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
